@font-face {
    font-family: 'Satoshi';
    src: url('../assets/fonts/Satoshi-Black.eot');
    src: url('../assets/fonts/Satoshi-Black.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Satoshi-Black.woff') format('woff'),
        url('../assets/fonts/Satoshi-Black.ttf') format('truetype'),
        url('../assets/fonts/Satoshi-Black.svg#Satoshi-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../assets/fonts/Satoshi-Bold.eot');
    src: url('../assets/fonts/Satoshi-Bold.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Satoshi-Bold.woff') format('woff'),
        url('../assets/fonts/Satoshi-Bold.ttf') format('truetype'),
        url('../assets/fonts/Satoshi-Bold.svg#Satoshi-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../assets/fonts/Satoshi-Medium.eot');
    src: url('../assets/fonts/Satoshi-Medium.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Satoshi-Medium.woff') format('woff'),
        url('../assets/fonts/Satoshi-Medium.ttf') format('truetype'),
        url('../assets/fonts/Satoshi-Medium.svg#Satoshi-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Satoshi';
    src: url('../assets/fonts/Satoshi-Regular.eot');
    src: url('../assets/fonts/Satoshi-Regular.eot?#iefix') format('embedded-opentype'),
        url('../assets/fonts/Satoshi-Regular.woff') format('woff'),
        url('../assets/fonts/Satoshi-Regular.ttf') format('truetype'),
        url('../assets/fonts/Satoshi-Regular.svg#Satoshi-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

$font_size_heading_large: 36px;
$font_size_heading_extra_large: 32px;
$font_size_heading_extra_extra_large: 52px;
$font_size_heading: 20px;
$font_size_heading_small: 18px;
$font_size_body: 16px;
$font_size_body_medium: 14px;
$font_size_body_small: 12px;
$font_size_body_extra_small: 9px;

@mixin primary_regular_font ($size) {
    font-family: 'Satoshi', sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: $size;
}

@mixin primary_medium_font ($size) {
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    font-style: normal;
    font-size: $size;
}

@mixin primary_bold_font ($size) {
    font-family: 'Satoshi', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: $size;
}

@mixin primary_black_font ($size) {
    font-family: 'Satoshi', sans-serif;
    font-weight: 900;
    font-style: normal;
    font-size: $size;
}

.primary-regular-font {
    font-family: 'Satoshi', sans-serif;
    font-weight: normal;
    font-style: normal;  
}

.primary-medium-font {
    font-family: 'Satoshi', sans-serif;
    font-weight: 500;
    font-style: normal;
}

.primary-bold-font {
    font-family: 'Satoshi', sans-serif;
    font-weight: bold;
    font-style: normal;
}

.primary-black-font {
    font-family: 'Satoshi', sans-serif;
    font-weight: 900;
    font-style: normal;
}

.font-size-heading-large {
    font-size: 36px;
}

.font-size-heading-extra-large {
    font-size: 32px;
}

.font-size-heading-extra-extra-large {
    font-size: 52px;
}

.font-size-heading {
    font-size: 20px;
}

.font-size-heading-small {
    font-size: 18px;
}

.font-size-body {
    font-size: 16px;
}

.font-size-body-medium {
    font-size: 14px;
}

.font-size-body-small {
    font-size: 12px;
}

.font-size-body-extra-small {
    font-size: 9px;
}