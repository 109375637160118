.ka-table {
	@import "./KaTableTheme.scss";
	@import "ka-table/style.scss";
	@import "../../../styles/fonts.scss";

	.ka-table-wrapper {
		min-height: 500px;
		height: auto;
	}

	input {
		vertical-align: middle;
	}

	.ka-thead-cell {
		color: rgba(var(--primary), 1.0);
	}
	.ka-icon-sort-arrow-down, .ka-icon-sort-arrow-up {
		color: rgba(var(--primary), 1.0);
	}

	.ka {
		border-radius: 12px;
		border: 1px solid  var(--primary-100);
	}

	.ka-cell-text {
		@include primary_regular_font($font_size_body_medium);
	}

	.ka-thead-cell-content {
		@include primary_medium_font($font_size_heading_small);
	}

	.ka-loading {
		background-color: var(--surface);
	}

	.ka-row {
		border-bottom: 1px solid var(--primary-200);
		border-top: 1px solid var(--primary-200);
	}

	.ka-thead-cell-content {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 5px;
	}

	.ka-input {
		border-radius: 12px;
		background-color: var(--primary-100);
		border-color: var(--primary-200);
	}

	.ka-loading-text {
		@include primary_medium_font($font_size_heading_small);
	}
}
