@import "../styles/fonts.scss";
@import "../styles/colors.scss";
@import "../styles/media_queries.scss";

$sidebar-width: 80px;

html body {
	background-color: black;
	color: var(--primary-dark-light);
}

.no-select {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.chevron::before {
	border-style: solid;
	border-width: 0.2em 0.2em 0 0;
	content: "";
	display: inline-block;
	height: 1em;
	left: 0.15em;
	position: relative;
	top: 0.25em;
	transform: rotate(-45deg);
	vertical-align: top;
	width: 1em;
}

.chevron.left:before {
	left: 0.25em;
	transform: rotate(-135deg);
}

.option-dots {
	filter: var(--brand-colour-red-filter);
	cursor: pointer;
}

#option-button:focus {
	outline: none !important;
	outline-offset: none !important;
	box-shadow: none !important;
}

#option-button {
	background-color: transparent;
	border-color: transparent;
}

img {
	pointer-events: none;
}

.gutter {
	pointer-events: none;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.loading-modal-with-text {
	text-align: center;

	h4 {
		@include primary_regular_font($font_size_heading);
		color: var(--brand-colour-blue);
	}

	.loading-spinner {
		color: var(--brand-colour-blue);
	}
}

.loading-modal-dialog {
	width: auto !important;
	max-width: 320px !important;
	display: inline-flex !important;
}

.alert-title,
.alert-description {
	color: var(--brand-colour-blue);
}

.alert-title {
	@include primary_medium_font($font_size_heading);
}

.alert-description {
	@include primary_regular_font($font_size_heading_small);
}

.alert-button {
	@include primary_regular_font($font_size_heading);
	background-color: var(--brand-colour-blue) !important;
	border-color: var(--brand-colour-blue) !important;
	color: var(--brand-colour-gray) !important;
}

.top-navigation-bar {
	width: 100%;
	height: 64px;
	background-color: var(--primary-dark-light);
}

.toast-body {
	background-color: rgba(var(--success-green-rgb), 1);
	@include primary_regular_font($font_size_body);
}

.toast-warning {
	background-color: rgba(var(--warning-yellow-rgb), 1) !important;
}

.meco-nav-bar {
	position: fixed;
	z-index: 3;
	top: 0;
	width: calc(100% - #{$sidebar-width});
	height: 64px;
	background-color: rgba(var(--group-email-card-rgb), 0.8);
	backdrop-filter: blur(20px);
	border-bottom: 1px solid var(--secondary-light-dark);

	&.extended-nav-bar {
		height: 130px;
	}

	&.standard {
		color: var(--primary-dark-light);
		-webkit-backdrop-filter: blur(20px);
		backdrop-filter: blur(20px);
		background-color: var(--header-alpha);
		z-index: 99;
		border-top: 0;
		border-bottom: 1px solid rgba(var(--primary-dark-light-rgb), 0.2);
		max-width: inherit !important;
		position: relative;
		width: inherit !important;
		padding-top: 15px;
	}

	&.short {
		height: 88px;
	}

	.meco-nav-bar-container {
		max-width: 600px;
		padding: 0 30px;
		margin: 0 auto;
		padding-top: 18px;
	}

	.title {
		text-align: center;
		@include primary_medium_font($font_size_heading_small);
		color: var(--primary-dark-light);
	}

	.button {
		text-align: left;
		cursor: pointer;
		@include primary_medium_font($font_size_body);
		color: var(--brand-colour-red);
		text-decoration: none;
		display: block;
		transition: text-decoration 0.25s ease;

		&:active {
			transform: scale(0.95);
		}

		&:hover {
			text-decoration: underline;
		}
	}

	.sub-nav {
		margin-top: 30px;
	}
}

.meco_container {
	align-items: center;
	border-radius: 30px;
	display: flex;
	flex: 0 0 auto;
	flex-flow: column;
	margin: 0 auto;
	gap: 15px;
	height: auto;
	overflow: visible;
	position: relative;
	width: 100%;

	@include md {
		background-color: rgba(255, 255, 255, 0.1);
		border: 1px rgba(238, 238, 238, 0.2) solid;
		box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 44px 0px;
		backdrop-filter: blur(2px);
		-webkit-backdrop-filter: blur(2px);
		margin-top: 40px;
		margin-bottom: 40px;
	}
}

.cta-button {
	align-items: center;
	justify-content: center;
	max-width: 400px;
	background-color: rgba(var(--success-green-rgb), 1);
	@include primary_medium_font($font_size_heading);
	height: 55px;
	color: white;
	border-radius: 25px;
	text-align: center;
	cursor: pointer;

	&.disabled {
		background-color: rgba(255, 255, 255, 0.2);
		color: rgba(255, 255, 255, 0.5);
	}
}

.green_gradient,
.red_gradient,
.gradient_overlay,
.container_overlay,
.particles {
	height: 100%;
	min-height: 100vh; //Fix scrolling bug on iOS Safari with fixed elements
	position: fixed;
	width: 100%;
}

.green_gradient {
	background: radial-gradient(60.4% 98.7% at -11.2% 124.3%, rgba(7, 171, 54, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
	bottom: 0px;
	left: 0px;
	z-index: -1;
}

.red_gradient {
	background: radial-gradient(50% 52.1% at 106.7% 90%, rgba(180, 199, 41, 0.3) 0%, rgba(25, 29, 46, 0) 100%);
	bottom: 0px;
	right: 0px;
	z-index: -1;
}

.gradient_overlay {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, #191d2e 140%);
	bottom: 0px;
	right: 0px;
	z-index: -3;
}

.particles {
	top: 0px;
	left: 0px;
	z-index: -2;
}

.container_overlay {
	top: 0px;
	left: 0px;
	z-index: -1;
}

.meco_logo {
	height: 30px;
	filter: brightness(0) invert(1);
}

.checkbox-round {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	vertical-align: middle;
	border: 2px solid white;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
	background-color: transparent !important;

	&.green {
		width: 30px;
		height: 30px;
		border: 2px solid rgba(var(--success-green-rgb), 1);
	}
}

.checkbox-round:checked {
	background-color: white !important;
	fill: white;
	-webkit-mask-image: url("../assets/images/check_mark_selected.svg");
	mask-image: url("../assets/images/check_mark_selected.svg");
	background-image: none !important;

	&.green {
		mask-size: 32px;
		background-color: rgba(var(--success-green-rgb), 1) !important;
	}
}

.checkbox-round:focus {
	outline: 0 !important;
	outline-offset: 0 !important;
	box-shadow: none !important;
}

.sender-search-hidden {
	width: 0px;
	transition: width 0.5s ease;
	box-sizing: border-box;
	opacity: 0;
	padding: 0 !important;
}

.sender-search-expanded {
	width: 200px;
	opacity: 1;
	transition: width 0.5s ease;
	box-sizing: border-box;
	padding: inherit;
}

.popover-panel::before {
	content: "";
    position: absolute;
    top: -30px; /* Adjust this value to move the arrow up or down */
    left: 50%; /* Center the arrow */
    transform: translateX(-50%);
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent blue transparent; /* This creates the arrow */
}

.lucide {
	color: rgba(var(--primary), 1);
	stroke-width: 1.5px;
  }

  .rdp {
	border: 0px !important;
  }