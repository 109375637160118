@import "../../../styles/fonts.scss";
@import "../../../styles/colors.scss";

.notification_banner {
	border: 1px solid var(--group-email-card);
	border-radius: 10px;
	padding: 20px;
	@include primary_regular_font($font_size_heading_small);
	margin-bottom: 60px;
	color: var(--primary-dark-light);

	.alert_note {
		@include primary_regular_font($font_size_body_small);
		color: rgba(var(--primary-dark-light-rgb), 0.5);
	}
}

.off_warning {
	@include primary_medium_font($font_size_body);
	text-align: left;
	background-color: var(--unsubscribe-red);
	padding: 20px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.modal_title {
	@include primary_medium_font($font_size_heading);
	color: rgba(var(--primary), 1);}

.number_circle {
	border-radius: 50%;
	width: 46px;
	height: 46px;
	line-height: 1.2;
	padding-top: 2px;
	background: rgba(var(--success-green-rgb), 1);
	color: white;
	text-align: center;
	@include primary_regular_font($font_size_heading_extra_large);
}

.step_name {
	@include primary_medium_font($font_size_heading);
	color: rgba(var(--primary), 1);}

.success_button {
	@include primary_medium_font($font_size_heading_small);
}

.api_key_description {
	@include primary_regular_font($font_size_body);
	color: rgba(var(--primary), 1);}

.field_title {
	@include primary_medium_font($font_size_heading_small);
	color: rgba(var(--primary), 1);}

.button {
	@include primary_medium_font($font_size_body);
	background-color: rgba(var(--success-green-rgb), 1);
	height: 37px;
	color: white;
	border-radius: 10px;
	text-align: center;
	padding-top: 6px;
	cursor: pointer;
	border: none;
	padding-left: 20px;
	padding-right: 20px;
	display: inline-block;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	&.disabled {
		cursor: not-allowed;
		background-color: rgba(var(--success-green-rgb), 0.5);
		color: rgba(255, 255, 255, 0.5);
	}

	&.outline {
		background-color: transparent;
		border: 1px solid rgba(var(--success-green-rgb), 1);
		color: rgba(var(--success-green-rgb), 1);
	}

	&.delete {
		background-color: transparent;
		border: 1px solid var(--brand-colour-red);
		color: var(--brand-colour-red);
		float: right;
	}
}

.btn-outline-primary {
	--bs-btn-color: rgba(var(--primary), 1);	--bs-btn-border-color: rgba(var(--primary), 1);	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: rgba(var(--primary), 1);	--bs-btn-hover-border-color: rgba(var(--primary), 1);	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: rgba(var(--primary), 1);	--bs-btn-active-border-color: rgba(var(--primary), 1);	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: rgba(var(--primary), 1);	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: rgba(var(--primary), 1);	--bs-gradient: none;
	@include primary_medium_font($font_size_heading_small);
}

.callout {
	padding: 1.25rem;
	border: 1px solid #e9ecef;
	border-left-width: 0.25rem;
	border-radius: 0.25rem;
	@include primary_regular_font($font_size_body);
	color: rgba(var(--primary), 1);
	a {
		@include primary_medium_font($font_size_body);
		color: rgba(var(--success-green-rgb), 1);
		text-decoration: underline;
	}

	&.info {
		border-left-color: rgba(var(--success-green-rgb), 1);
	}
	&.warning {
		border-left-color: #f0ad4e;
	}

	&.danger {
		border-left-color: #d9534f;
	}
}

.instructions_list {
	margin-top: 10px;
	padding-left: 1rem;

	li {
		list-style: initial;
		margin: 10px 10px;
	}
}
