@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.monthInputField {
  width: 100px;
  height: 55px;
  min-width: 200px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  border: 1px solid var(--primary-200);
  background: var(--secondary);
  color: rgba(var(--primary), 1);
  transition: border-color 0.25s linear;
  @include primary_regular_font($font_size_body);
}

.monthInputField:hover {
  background: var(--surface-200);
  transition: background 0.2s linear;
}