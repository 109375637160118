// Small devices
@mixin sm {
    @media (min-width: 640px) {
        @content;
    }
 }
 
 // Medium devices
 @mixin md {
    @media (min-width: 768px) {
        @content;
    }
 }
 
 // Large devices
 @mixin lg {
    @media (min-width: 1024px) {
        @content;
    }
 }
 
 // Extra large devices
 @mixin xl {
    @media (min-width: 1280px) {
        @content;
    }
 }

  // Extra extra large devices
 @mixin xxl {
    @media (min-width: 1536px) {
        @content;
    }
 }