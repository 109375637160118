$ka-background-color: var(--surface);
$ka-border-color: #4d4d4d;
$ka-color-base: rgba(var(--primary), 1.0);
$ka-input-background-color: $ka-background-color;
$ka-input-border-color: $ka-border-color;
$ka-input-color: $ka-color-base;
$ka-row-hover-background-color: transparentize(#fff, 0.9);
$ka-thead-background-color: var(--primary-200-hex);
$ka-thead-color: red;
$ka-selected-background-color: rgba(var(--success-green-rgb), 0.2);
$ka-row-hover-background-color: rgba(var(--success-green-rgb), 0.1);