@import "../../styles/fonts.scss";
@import "../../styles/colors.scss";

.month:hover {
  color: var(--secondary);
  border-radius: 10px;
  background-color: var(--primary-dark-light);
}

.month {
  padding: 8px;
  border: solid 1px transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color:var(--primary-dark-light);
  font-size: 16px;
}

.active {
  border-radius: 10px;
  background: rgba(var(--success-green-rgb), 1.0) !important;
}

.yearContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 16px;
}

.monthsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0.5rem;
}

.pickerContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 7px;
  border: 1px solid rgba(0,0,0,0.35);
  color: var(--primary-dark-light);
  position: absolute;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
  background: var(--secondary);
  width: 200px;
  @include primary_regular_font($font_size_body);
  margin-top: 10px;
  z-index: 9999;
}

button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.bold1 {
  font-size: 1.4rem;
}

.pickerContainer::selection {
  background-color: transparent;
}