:root {
    
    --sidebar-color: 236 236 236;
    --primary: 45 45 46;
    --primary-200-hex: #D5D5D5;
    --primary-500: rgba(45,45,46,0.5);
    --primary-200: rgba(45,45,46,0.2);
    --primary-100: rgba(45,45,46,0.1);
    --primary-default: rgba(45,45,46,1.0);
    --secondary: rgba(242,242,242,0.98);
    --surface: rgba(255,255,255, 1.0);
    --surface-100: rgba(255, 255, 255, 0.25);
    --surface-200: #F8F8F8;
    --contrast: 255 255 255;
    





    --primary-dark-light-tail: 0 5 24;
    --secondary-light-dark-tail: 238 238 238;
    --group-email-card-tail: 255 255 255;
    
    --primary-dark-light: #000518;
    --header-alpha: rgb(238, 238, 238, 0.75);
    
    --primary-dark-light-rgb: 0,5,24;
    --secondary-light-dark-rgb: 238, 238, 238;

    --feed-detail-header-overlay: rgb(255, 255, 255, 0.8);

    --primary-dark-light-filter: invert(4%) sepia(27%) saturate(5210%) hue-rotate(201deg) brightness(92%) contrast(106%);
    --secondary-light-dark-filter: invert(100%) sepia(1%) saturate(3337%) hue-rotate(35deg) brightness(123%) contrast(87%);

    --secondary-light-dark: #EEEEEE;
    --feed-green-rgb: 196,255,207;
    --group-email-card: #FFFFFF;
    --group-email-card-rgb: 255, 255, 255;
    --header-background: rgba(255,255,255,0.7);
    --header-gradient: #FFFFFF;
    --success-green-rgb: 7,171,54;
    --brand-blue-rgb: 0,5,24;
    --success-green-filter: invert(32%) sepia(94%) saturate(1680%) hue-rotate(120deg) brightness(102%) contrast(94%);

    --warning-yellow-rgb: 171,150,7;

    --unread: #1F85FF; 
    --unsubscribe-red: #FFC4C4; 

    --brand-colour-red: #FF4444;
    --brand-colour-red-filter: invert(46%) sepia(33%) saturate(6400%) hue-rotate(334deg) brightness(101%) contrast(116%);

    --brand-colour-blue: rgb(0, 5, 24);
    --brand-colour-gray: #EEEEEE;

    --light-gray: rgba(0,0,0, 0.667);
}
[data-theme="dark"] {

    --sidebar-color: 10 10 10;
    --primary: 238 238 238;
    --primary-200-hex: #565656;
    --primary-500: rgba(238,238,238,0.5);
    --primary-200: rgba(238,238,238,0.2);
    --primary-100: rgba(238,238,238,0.1);
    --primary-default: rgba(238,238,238,1.0); 
    --secondary: rgba(10,10,10,0.98);
    --surface: rgba(255,255,255, 0.1);
    --surface-100: rgba(255, 255, 255, 0.2);
    --surface-200: #171717;
    --contrast: 0 0 0;




















    --primary-dark-light-tail: 238 238 238;
    --secondary-light-dark-tail: 0 5 24;
    --group-email-card-tail: 25 29 46;

    --primary-dark-light: #EEEEEE;
    --header-alpha:rgb(0, 5, 24, 0.75);

    --primary-dark-light-rgb: 238, 238, 238;
    --secondary-light-dark-rgb: 0,5,24;

    --feed-detail-header-overlay: rgb(0, 5, 24, 0.8);

    --primary-dark-light-filter: invert(100%) sepia(1%) saturate(3337%) hue-rotate(35deg) brightness(123%) contrast(87%);
    --secondary-light-dark-filter: invert(4%) sepia(27%) saturate(5210%) hue-rotate(201deg) brightness(92%) contrast(106%);

    --secondary-light-dark: #000518;
    --feed-green-rgb: 7,171,54;
    --group-email-card: #191D2E;
    --group-email-card-rgb: 25,29,46;
    --header-background: rgba(0,5,24,0.4);
    --header-gradient: #000518;
    --success-green-rgb: 7,171,54;
    --brand-blue-rgb: 0,5,24;
    --success-green-filter: invert(31%) sepia(70%) saturate(2338%) hue-rotate(125deg) brightness(105%) contrast(94%);

    --warning-yellow-rgb: 171,150,7;

    --unread: #489AFC; 
    --unsubscribe-red: #C72929; 

    --brand-colour-red: #FF4444;
    --brand-colour-red-filter: invert(46%) sepia(33%) saturate(6400%) hue-rotate(334deg) brightness(101%) contrast(116%);

    --brand-colour-blue: #000518;
    --brand-colour-gray: #EEEEEE;

    --light-gray: rgba(255,255,255, 0.667);
}